export var RPSActions;
(function (RPSActions) {
    RPSActions["ROCK"] = "R";
    RPSActions["PAPER"] = "P";
    RPSActions["SCISSORS"] = "S";
})(RPSActions || (RPSActions = {}));
export var RPSExtendedStates;
(function (RPSExtendedStates) {
    RPSExtendedStates["DID_NOT_PLAY"] = "-";
    RPSExtendedStates["PENDING"] = "*";
})(RPSExtendedStates || (RPSExtendedStates = {}));
export var GameResult;
(function (GameResult) {
    GameResult["WIN"] = "win";
    GameResult["TIE"] = "tie";
    GameResult["LOSS"] = "loss";
})(GameResult || (GameResult = {}));
export var ShortNotationGameResult;
(function (ShortNotationGameResult) {
    ShortNotationGameResult["WIN"] = "w";
    ShortNotationGameResult["TIE"] = "t";
    ShortNotationGameResult["LOSS"] = "l";
})(ShortNotationGameResult || (ShortNotationGameResult = {}));
